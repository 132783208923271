/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Wordpress menu function
        var $window = $(window);
        if($('.dropdown').length){

          if($window.width() > 1201){
            $('.dropdown > a').addClass('dropdown-toggle').attr('data-toggle', 'dropdown');
          }else{
            $('.dropdown > a').addClass('dropdown-toggle').attr('data-toggle', 'dropdown');
          }

        }

        //Produkty homepage - change image
        if($('.home_produkty_items_holder').length){

          $('.home_produkty_items_holder ul li').hover(function(){
            var produktImg = $(this).attr('data-img');
            $('.home_produkty_items_foto div').css('background-image', 'url(' + produktImg + ')');
          });

        }


        $('.heading_with_line').wrap('<div class="heading_wrapper"></div>');

        //Reference slider homepage
        $(".owl-carousel").owlCarousel({
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1
                },
                678: {
                    items: 2
                },
                // breakpoint from 480 up
                992: {
                    items: 3
                },
                // breakpoint from 768 up
                1230: {
                    items: 4
                }
            }
        });

        var owl = $('.owl-carousel').owlCarousel();
        $(".reference_prev").click(function () {
            owl.trigger('prev.owl.carousel');
        });

        $(".reference_next").click(function () {
            owl.trigger('next.owl.carousel');
        });

          //Set height for download items - homepage
          if($(window).width() > 980){
              setTimeout(function(){
                  $('.home_stazeni_text').each(function(){
                      var text = $(this).height();
                      var ico = $(this).closest('.et_pb_row').find('.home_stazeni_ico').height();

                      var padding = ((text - ico ) / 2);
                      $(this).closest('.et_pb_row').find('.home_stazeni_ico').css({
                          'padding-top': padding,
                          'padding-bottom': padding
                      });

                  });
              }, 500);
          }


        //Set accordion color
        $('.detail_produkt_items_holder .card').on("click", function() {
            $(this).siblings().removeClass("card-active");
            $(this).toggleClass("card-active");
        });

        if($('.card-block').length){
            $('.card-block table').wrap('<div class="table-wrap"></div>');
        }

        //Magnific popup
        $('.gal_hlder').each(function() {
            $(this).magnificPopup({
                delegate: 'a', // child items selector, by clicking on it popup will open
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired






      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
